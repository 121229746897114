<template>
   <div class="contact_info">
      <div class="content">
         <h2 class="ttl">{{ data.title }}</h2>

         <div class="item">
            <p>{{ data.subtitle }}</p>
            <div class="list">
               <p>{{ data.email }}</p>
               <p>{{ $fn.tr("Contact us ") }} <a class="link-with-hover-white" :href="`tel:${data.phone}`">{{ data.phone }}</a></p>
               <p>{{ data.address }}</p>
            </div>
         </div>

         <div class="item">
            <p>{{ $fn.tr("Working Hours:") }}</p>
            <p>{{ data.working_hours }}</p>
            <p>{{ $fn.tr("Call Us ") }} <a class="link-with-hover-white" :href="`tel:${data.mobile}`">{{ data.mobile }}</a></p>
         </div>

         <div class="socials">
            <a class="link-with-hover-white" :href="$get(footer_social_links, 'fb.url')"
               :target="$get(footer_social_links, 'fb.targetblank') ? '_blank' : 'self'">
               <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                     d="M15.7796 21.7807H18.3836V31.7086C18.3836 31.9046 18.5551 32.0635 18.7668 32.0635H23.1819C23.3936 32.0635 23.5651 31.9046 23.5651 31.7086V21.8274H26.5586C26.7532 21.8274 26.917 21.6922 26.9392 21.5131L27.3939 17.8581C27.4064 17.7576 27.372 17.6569 27.2993 17.5814C27.2266 17.5059 27.1225 17.4627 27.0133 17.4627H23.5653V15.1716C23.5653 14.4809 23.9668 14.1307 24.7589 14.1307C24.8717 14.1307 27.0133 14.1307 27.0133 14.1307C27.225 14.1307 27.3965 13.9718 27.3965 13.7759V10.4209C27.3965 10.2249 27.225 10.0661 27.0133 10.0661H23.9063C23.8844 10.0651 23.8358 10.0635 23.764 10.0635C23.2249 10.0635 21.3511 10.1615 19.8709 11.4226C18.2308 12.8201 18.4588 14.4934 18.5133 14.7835V17.4626H15.7796C15.568 17.4626 15.3965 17.6215 15.3965 17.8175V21.4258C15.3965 21.6218 15.568 21.7807 15.7796 21.7807Z"
                     fill="white" />
               </svg>
            </a>

            <a class="link-with-hover-white" :href="$get(footer_social_links, 'instagram.url')"
               :target="$get(footer_social_links, 'instagram.targetblank') ? '_blank' : 'self'">
               <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                     d="M15.7033 7.5083H27.0897C31.4273 7.5083 34.9517 11.0326 34.9517 15.3703V26.7567C34.9517 28.8418 34.1233 30.8415 32.6489 32.3159C31.1745 33.7903 29.1748 34.6187 27.0897 34.6187H15.7033C11.3657 34.6187 7.84131 31.0943 7.84131 26.7567V15.3703C7.84131 13.2852 8.66962 11.2854 10.144 9.81103C11.6184 8.33662 13.6182 7.5083 15.7033 7.5083ZM15.4322 10.2193C14.138 10.2193 12.8968 10.7335 11.9816 11.6486C11.0665 12.5638 10.5523 13.805 10.5523 15.0992V27.0278C10.5523 29.7252 12.7347 31.9076 15.4322 31.9076H27.3608C28.655 31.9076 29.8962 31.3935 30.8113 30.4783C31.7265 29.5632 32.2406 28.322 32.2406 27.0278V15.0992C32.2406 12.4017 30.0582 10.2193 27.3608 10.2193H15.4322ZM28.513 12.2526C28.9623 12.2526 29.3933 12.4311 29.7111 12.7489C30.0288 13.0667 30.2073 13.4976 30.2073 13.947C30.2073 14.3964 30.0288 14.8274 29.7111 15.1451C29.3933 15.4629 28.9623 15.6414 28.513 15.6414C28.0636 15.6414 27.6326 15.4629 27.3148 15.1451C26.9971 14.8274 26.8186 14.3964 26.8186 13.947C26.8186 13.4976 26.9971 13.0667 27.3148 12.7489C27.6326 12.4311 28.0636 12.2526 28.513 12.2526ZM21.3965 14.2859C23.194 14.2859 24.9179 15 26.189 16.271C27.46 17.542 28.1741 19.2659 28.1741 21.0635C28.1741 22.861 27.46 24.5849 26.189 25.856C24.9179 27.127 23.194 27.8411 21.3965 27.8411C19.599 27.8411 17.8751 27.127 16.604 25.856C15.333 24.5849 14.6189 22.861 14.6189 21.0635C14.6189 19.2659 15.333 17.542 16.604 16.271C17.8751 15 19.599 14.2859 21.3965 14.2859ZM21.3965 16.9969C20.318 16.9969 19.2836 17.4254 18.521 18.188C17.7584 18.9506 17.3299 19.985 17.3299 21.0635C17.3299 22.142 17.7584 23.1763 18.521 23.939C19.2836 24.7016 20.318 25.13 21.3965 25.13C22.475 25.13 23.5093 24.7016 24.272 23.939C25.0346 23.1763 25.463 22.142 25.463 21.0635C25.463 19.985 25.0346 18.9506 24.272 18.188C23.5093 17.4254 22.475 16.9969 21.3965 16.9969Z"
                     fill="white" />
               </svg>

            </a>
         </div>

         <div class="btn" @click="favoriteStore.setContactSale(true)">
            <div class="bg"></div>
            <span>{{ $fn.tr("Need help? Message us") }}</span>
         </div>
      </div>

      <div class="img-box">
         <picture>
            <source media="(max-width: 767px)" :srcset="$get(data, 'image.0.devices.desktop')" />
            <source  media="(max-width: 1023px)" :srcset="$get(data, 'image.0.devices.tablet')" />
            <img :src="$get(data, 'image.0.devices.desktop')"
               :alt="$get(data, 'image.0.title')" />
         </picture>
      </div>
   </div>
</template>

<script setup>
import { computed } from 'vue';
import { useMenuStore } from '@/store/pinia/menu/index';
import { UseFavoriteFlats } from '@/store/pinia/FavoriteProjects';

const store = useMenuStore();
const favoriteStore = UseFavoriteFlats()

const data = computed(() => {
   return store.indx.widgets.sales_office;
})

const footer_social_links = computed(() => {
   return store.indx.widgets.footer_social_links;
})
</script>

<style lang="scss" scoped>
.contact_info {
   display: flex;
   padding: 107px 120px;
   background-color: #050505;
}

.contact_info * {
   color: #fff;
}

.content {
   width: 470px;
   flex: none;
}

.ttl {
   font-size: 48px;
}

.item {
   margin-top: 58px;
}

.item * {
   font-size: 24px;
}

.item p {
   margin-bottom: 13px;
}

.item p:last-child {
   margin-bottom: 0;
}

.socials {
   margin-top: 42px;
   display: flex;
   align-items: center;

   a {
      margin-right: 40px;
   } 

   a:last-child {
      margin-right: 0;
   }
}


.btn {
   margin-top: 42px;
   display: block;
   overflow: hidden;
   position: relative;
   width: 100%;
   height: 109px;
   border: 1px solid #FFFFFF;

   display: flex;
   justify-content: center;
   align-items: center;

   cursor: pointer;

   span {
      position: relative;
      font-size: 34px;
      color: #fff;
      transition: 0.8s cubic-bezier(.22, -0.01, .34, 1);
   }

   &:hover span {
      color: #000000;
   }

   .bg {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      transition: 0.8s cubic-bezier(.22, -0.01, .34, 1);
      transform: translateX(-100%);
      background: white;
   }

   &:hover .bg {
      transform: translateX(0%);
   }
}

.img-box {
   position: relative;
   flex: 1;
   padding-top: 43.8%;
   height: 0;
   margin-left: 100px;
   clip-path: polygon(0% 16.19%, 11.08% 16.19%, 11.08% 0%, 85.53% 0%, 85.53% 6.5%, 100% 6.5%, 100% 82.67%, 89.26% 82.67%, 89.26% 90.2%, 79.02% 90.2%, 79.02% 100%, 0% 100%);
   overflow: hidden;

   img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
   }
}


@media screen and(max-width: 1900px) {
   .contact_info {
      padding: 99px 100px;
   }
}

@media screen and(max-width: 1599px) {
   .contact_info {
      padding: 60px 100px;
   }
   .img-box{
      padding-top:  39.8%;;
   }
   .ttl {
      font-size: 34px;
   }

   .item {
      margin-top: 21px;
   }

   .item * {
      font-size: 21px;
   }

   .item p{
      margin-bottom: 8px;
   }

   .socials,
   .btn{
      margin-top: 34px;
   }

   .btn {
      height: 80px;

      span {
         font-size: 21px;
      }
   }
}

@media screen and(max-width: 1439px) {
   .contact_info {
      padding: 60px 80px;
   }

   .img-box {
      margin-left: 60px;
   }

}

@media screen and(max-width: 1279px) {
   .contact_info {
      padding: 60px 48px;
   }
}

@media screen and(max-width: 1023px) {
   .content {
      width: 320px;
   }

   .img-box {
      margin-left: 30px;
   }
}

@media screen and(max-width: 767px) {
   .contact_info {
      padding: 30px 16px;
   }

   .img-box {
      display: none;
   }

   .content {
      width: 100%;
   }
}</style>
